import React, { Component } from "react";
import FormReservationInputs from "./FormReservationInputs.js";
import { TermsAndConditions } from "../../components";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { translate } from "../../translations/localization.js";
import "./styles.css";

class FormReservation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      open: false,
      openFormReservation: false,
    };
  }

  toggleFormReservation() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenModalFormReservation = () => {
    this.setState({ openFormReservation: true });
  };
  onCloseModalFormReservation = () => {
    this.setState({ openFormReservation: false });
  };

  render() {
    const { open, openFormReservation } = this.state;

    const formReservationIsOpen = !this.state.isOpen;
    let formReservationStyles;

    if (formReservationIsOpen) {
      formReservationStyles = "form-reservation-wrap Close";
    } else {
      formReservationStyles = "form-reservation-wrap Open ";
    }

    return (
      <div id="form-reservation" className={formReservationStyles}>
        <div className="container">
          <div className="row row-1">
            <div className="col col1">
              <h4 onClick={this.onOpenModal} className="link">
                {translate("tBookingConditions")}
              </h4>
              <Modal
                open={open}
                onClose={this.onCloseModal}
                classNames={{
                  overlay: "customModalOverlay",
                  modal: "customModal",
                }}
              >
                <TermsAndConditions />
              </Modal>
            </div>
            <div className="col col2">
              <h4
                className="bttn-open-close desktop-form-reservation"
                onClick={() => this.toggleFormReservation()}
              >
                {translate("tBookNow")}
              </h4>

              <div className="mobile-form-reservation">
                <h4
                  onClick={this.onOpenModalFormReservation}
                  className="bttn-open-close"
                >
                  {translate("tBookNow")}
                </h4>
                <Modal
                  open={openFormReservation}
                  onClose={this.onCloseModalFormReservation}
                  classNames={{
                    overlay: "customModalOverlay",
                    modal: "customModal",
                  }}
                >
                  <FormReservationInputs />
                </Modal>
              </div>
            </div>
            <div className="col col3" />
          </div>
          <div className="row row-2">
            <div className="col desktop-form-reservation">
              <FormReservationInputs />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(FormReservation);
