import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Route, Switch, Router } from "react-router";
import { withRouter } from "react-router-dom";
import { history } from "../../config/stores";
import {
  RoomTypes,
  Gallery,
  Events,
  NewHome,
  NewAboutUs,
  NewRestaurantsAndBars,
  NewSportAndSpa,
  NewGallery,
} from "../../screens";

class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="root-all">
          <TransitionGroup>
            <CSSTransition classNames="fade" enter leave timeout={1000}>
              <Switch>
                <Route exact path="/:lang?" component={NewHome} />
                <Route path="/:lang?/room-types" component={RoomTypes} />
                <Route path="/:lang?/restaurants-and-bars" component={NewRestaurantsAndBars} />
                <Route path="/:lang?/sport-and-spa" component={NewSportAndSpa} />
                <Route exact path="/:lang?/about-us" component={NewAboutUs} />
                <Route exact path="/:lang?/gallery" component={NewGallery} />
                <Route exact path="/:lang?/events" component={Events} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.starter.loading,
    language: state.language,
  };
};

export default withRouter(connect(mapStateToProps)(Routes));
