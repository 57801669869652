import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Header, Footer, ReservationBanner, VideoBackground } from "../../components";
import { getData } from "../../actions";
import { translate } from "../../translations/localization.js";
import "./styles.css";
import { useEffect } from "react";
const NewHome = ({ language, sections, getData }) => {
  useEffect(() => {
    getData({ page: "home" });
  }, []);

  console.log(sections.filter((sect) => sect.for === "header")[0]);

  return (
    <div className="wrap-all home">
      <Header fields={sections.filter((sect) => sect.for === "header")[0]} />

      <section
        className="section-header"
        style={{
          backgroundImage: 'url("/imgs/apolonia-hotel-home-header.jpg")',
        }}
      >
        <VideoBackground />

        <ReservationBanner />
      </section>
      <div
        className="section section-accommodation overlay-gradient-1"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "accomodation")?.[0]?.fields?.images?.[0]})`,
        }}
      >
        <div className="row">
          <div className="col">
            <h2>{translate("tAccommodation")}</h2>
            {language === "bg" ? (
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "accomodation")?.[0]?.fields?.text }}></p>
            ) : (
              <p>{translate("homeP1")}</p>
            )}
            <p>
              <Link to={`/${language}/room-types`} className="link-more-white">
                {translate("tViewMore")}
              </Link>
            </p>
          </div>
        </div>
      </div>

      <section className="section section-restaurants">
        <div className="row">
          {sections
            ?.filter((sect) => sect.for === "restaurants")?.[0]
            ?.fields?.images?.map((img) => {
              return (
                <div
                  className="col"
                  style={{
                    backgroundImage: `url(${img})`,
                  }}
                >
                  &nbsp;
                </div>
              );
            })}
          <div className="col-auto col-30 bg-1">
            <h2>{translate("tRestaurantsAndBars")}</h2>
            <p className="icons-list">
              <span className="icon-restaurant" />
              <span className="icon-cake" />
              <span className="icon-drink" />
              <span className="icon-cocktail-2" />
            </p>
            {language === "bg" ? (
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "restaurants")?.[0]?.fields?.text }}></p>
            ) : (
              <p>{translate("homeP2")}</p>
            )}
            <p>
              <Link to={`/${language}/restaurants-and-bars`} className="link-more-white">
                {translate("tViewMore")}
              </Link>
            </p>
          </div>
        </div>
      </section>

      <section className="section section-sport">
        <div className="row">
          <div className="col-auto col-30 bg-1">
            <h2>{translate("tSportAndSpa")}</h2>
            <p className="icons-list">
              <span className="icon-ball" />
              <span className="icon-gym" />
              <span className="icon-party" />
              <span className="icon-pool" />
            </p>
            {language === "bg" ? (
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "sports")?.[0]?.fields?.text }}></p>
            ) : (
              <p>{translate("homeP3")}</p>
            )}
            <p>
              <Link to={`/${language}/sport-and-spa`} className="link-more-white">
                {translate("tViewMore")}
              </Link>
            </p>
          </div>
          {sections
            ?.filter((sect) => sect.for === "sports")?.[0]
            ?.fields?.images?.map((img) => {
              return (
                <div
                  className="col"
                  style={{
                    backgroundImage: `url(${img})`,
                  }}
                >
                  &nbsp;
                </div>
              );
            })}
        </div>
      </section>

      <section className="section section-gallery">
        <div className="row">
          <div
            className="col"
            style={{
              backgroundImage: `url(${sections?.filter((sect) => sect.for === "gallery")?.[0]?.fields?.images[0]})`,
            }}
          >
            &nbsp;
          </div>
          <div className="col-auto col-30 bg-1">
            <h2>{translate("tGallery")}</h2>
            {language === "bg" ? (
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "gallery")?.[0]?.fields?.text }}></p>
            ) : (
              <p>{translate("homeP4")}</p>
            )}
            <p>
              <Link to={`/${language}/gallery`} className="link-more-white">
                {translate("tViewMore")}
              </Link>
            </p>
          </div>
        </div>
      </section>
      {/* <section className="section section-sport" style={{ minHeight: "500px" }}>
        <div className="row" style={{ minHeight: "500px" }}>
          <div className="col-auto col-30 bg-1" style={{ minHeight: "500px" }}>
            <h2 style={{ fontSize: "50px" }}>{translate("homeEventTitle")}</h2>
            <p style={{ fontSize: "30px" }}>{translate("homeEventText")}</p>
          </div>
          <div
            className="col"
            style={{
              backgroundImage: 'url("/imgs/pacho_i_pepo.png")',
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            &nbsp;
          </div>
        </div>
      </section> */}
      <Footer
        fields={sections?.filter((sect) => sect.for === "footer")[0]?.fields}
        phones={sections.filter((sect) => sect.for === "header")[0]?.fields?.phone}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
    sections: state.data.sections,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewHome);
