import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { Header, Footer } from "../../components";
import { translate } from "../../translations/localization.js";
import { getData } from "../../actions";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "./styles.css";
import { useEffect } from "react";
import { useState } from "react";

const photosEvents = [
  "/imgs/apolonia-gallery-events-1.jpg",
  "/imgs/apolonia-gallery-events-2.jpg",
  "/imgs/apolonia-gallery-events-3.jpg",
  "/imgs/apolonia-gallery-events-4.jpg",
  "/imgs/apolonia-gallery-events-5.jpg",
  "/imgs/apolonia-gallery-events-6.jpg",
  "/imgs/apolonia-gallery-events-7.jpg",
  "/imgs/apolonia-gallery-events-8.jpg",
  "/imgs/apolonia-gallery-events-9.jpg",
];

const NewGallery = ({ language, sections, getData }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photosApoloniaResort, setPhotosApoloniaResort] = useState();
  const [isOpenApoloniaResort, setIsOpenApoloniaResort] = useState(false);
  const [photosBeachCommonAreas, setphotosBeachCommonAreas] = useState();
  const [isOpenBeachCommonAreas, setIsOpenBeachCommonAreas] = useState(false);
  const [isOpenEvents, setIsOpenEvents] = useState(false);

  useEffect(() => {
    getData({
      page: "gallery",
      onSuccess: (res) => {
        setPhotosApoloniaResort(res?.filter((sect) => sect.for === "events")?.[0]?.fields?.images);
        setphotosBeachCommonAreas(res?.filter((sect) => sect.for === "beach")?.[0]?.fields?.images);
      },
    });
  }, []);

  console.log(photosApoloniaResort);
  return (
    <div className="wrap-all page-gallery">
      <Header fields={sections.filter((sect) => sect.for === "header")[0]} />

      <div className="main">
        <div className="container">
          {isOpenApoloniaResort && (
            <Lightbox
              mainSrc={photosApoloniaResort[photoIndex]}
              nextSrc={photosApoloniaResort[(photoIndex + 1) % photosApoloniaResort.length]}
              prevSrc={photosApoloniaResort[(photoIndex + photosApoloniaResort.length - 1) % photosApoloniaResort.length]}
              onCloseRequest={() => setIsOpenApoloniaResort(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photosApoloniaResort.length - 1) % photosApoloniaResort.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photosApoloniaResort.length)}
            />
          )}

          {isOpenBeachCommonAreas && (
            <Lightbox
              mainSrc={photosBeachCommonAreas[photoIndex]}
              nextSrc={photosBeachCommonAreas[(photoIndex + 1) % photosBeachCommonAreas.length]}
              prevSrc={photosBeachCommonAreas[(photoIndex + photosBeachCommonAreas.length - 1) % photosBeachCommonAreas.length]}
              onCloseRequest={() => setIsOpenBeachCommonAreas(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photosBeachCommonAreas.length - 1) % photosBeachCommonAreas.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photosBeachCommonAreas.length)}
            />
          )}

          {isOpenEvents && (
            <Lightbox
              mainSrc={photosEvents[photoIndex]}
              nextSrc={photosEvents[(photoIndex + 1) % photosEvents.length]}
              prevSrc={photosEvents[(photoIndex + photosEvents.length - 1) % photosEvents.length]}
              onCloseRequest={() => setIsOpenEvents(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photosEvents.length - 1) % photosEvents.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photosEvents.length)}
            />
          )}

          <div className="gallery">
            <h2>{translate("tApoloniaResort")}</h2>
            <div className="gallery-photos">
              {photosApoloniaResort?.map((photo, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setIsOpenApoloniaResort(true);
                      setPhotoIndex(i);
                    }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundImage: `url(${photo})`,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="gallery">
            <h2>{translate("tBeachCommonAreas")}</h2>
            <div className="gallery-photos">
              {photosBeachCommonAreas?.map((photo, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setIsOpenBeachCommonAreas(true);
                      setPhotoIndex(i);
                    }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundImage: `url(${photo})`,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="gallery">
            <h2>{translate("tEvents")}</h2>
            <div className="gallery-photos">
              {photosEvents?.map((photo, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setIsOpenEvents(true);
                      setPhotoIndex(i);
                    }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundImage: `url(${photo})`,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <h2>{translate("tSozopol")}</h2>
          <p>{translate("galleryP1")}</p>
        </div>

        <div className="row row-gallery-about">
          <div
            className="col col-img"
            style={{
              backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.backgroundImage?.[0]})`,
            }}
          >
            &nbsp;
          </div>
          <div className="col col-info">
            <div className="headline-box">
              <h2 className="headline-up">{translate("tOldTown")}</h2>
            </div>
            <div className="info-box">
              {language === "bg" ? (
                <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.text }}></p>
              ) : (
                <>
                  <p>{translate("galleryP2")}</p>
                  <p>{translate("galleryP3")}</p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row row-gallery-about">
          <div className="col col-info align-right">
            <div className="headline-box align-right">
              <h2 className="headline-up">{translate("tIslandStIvan")}</h2>
            </div>
            <div className="info-box">
              {language === "bg" ? (
                <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "section_2")?.[0]?.fields?.text }}></p>
              ) : (
                <>
                  <p>{translate("galleryP4")}</p>
                  <p>{translate("galleryP5")}</p>
                </>
              )}
            </div>
          </div>
          <div
            className="col col-img"
            style={{
              backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "section_2")?.[0]?.fields?.backgroundImage?.[0]})`,
            }}
          >
            &nbsp;
          </div>
        </div>
      </div>

      <Footer  fields={sections?.filter((sect) => sect.for === "footer")[0]?.fields} phones={sections.filter((sect) => sect.for === "header")[0]?.fields?.phone}/>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
    sections: state.data.sections,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewGallery);
