import { DataTypes } from '../actions'

const initialState = {
  sections: []
}

const data = (state = initialState, action) => {
  switch (action.type) {
    case DataTypes.GET_DATA_SUCCESS:
      return { ...state, sections: action.payload }
    case DataTypes.UPDATE_DATA_SUCCESS:
      return { ...state }
    default:
      return state
  }
}

export default data
