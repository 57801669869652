import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Header, Footer, ReservationBanner } from "../../components";
import { getData } from "../../actions";
import { translate } from "../../translations/localization.js";
import "./styles.css";
import { useState } from "react";

const apoloniaBarImages = [
  "/imgs/bar-pool-main.jpg",
  "/imgs/pool-bar-image-2.jpg",
  "/imgs/pool-bar-image-3.jpg",
  "/imgs/pool-bar-image-4.jpg",
  "/imgs/pool-bar-image-5.jpg",
  "/imgs/pool-bar-image-6.jpg",
];

const NewRestaurantsAndBars = ({ language, sections, getData }) => {
  const [imageIndexes, setImageIndexes] = useState({
    fioreIndex: 0,
    poolBarIndex: 0,
    apoloniaBarIndex: 0,
    restaurantApoloniaIndex: 0,
  });

  useEffect(() => {
    getData({ page: "restaurants" });
  }, []);

  return (
    <div className="wrap-all restaurants-and-bars">
      <Header fields={sections.filter((sect) => sect.for === "header")[0]} />

      <section
        className="section-header overlay-gradient-4"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "landing")?.[0]?.fields?.backgroundImage?.[0]})`,
        }}
      >
        <div className="flex align-bottom">
          <div className="container">
            <h1>{translate("tRestaurantsAndBars")}</h1>
            <div className="main-info">
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "landing")?.[0]?.fields?.text }}></p>
            </div>
          </div>
        </div>
        <ReservationBanner />
      </section>

      <div className="main">
        <div className="container">
          <section>
            <div
              className="left-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "70%" : "50%",
                left: window.innerWidth < 900 ? "0" : "34%",
              }}
              onClick={() => {
                if (imageIndexes.restaurantApoloniaIndex > 0) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, restaurantApoloniaIndex: prevState.restaurantApoloniaIndex - 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return {
                      ...prevState,
                      restaurantApoloniaIndex: sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.backgroundImage?.length - 1,
                    };
                  });
                }
              }}
            />
            <div
              className="right-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                transform: "rotate(180deg)",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "70%" : "50%",
                right: window.innerWidth < 900 ? "0" : "10px",
              }}
              onClick={() => {
                if (
                  imageIndexes.restaurantApoloniaIndex <
                  sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.backgroundImage?.length - 1
                ) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, restaurantApoloniaIndex: prevState.restaurantApoloniaIndex + 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return { ...prevState, restaurantApoloniaIndex: 0 };
                  });
                }
              }}
            />
            <div className="row">
              <div className="col col-auto col-info">
                <div className="headline-box">
                  <h3>{translate("tRestaurant")}</h3>
                  <h2>{translate("rApolonia")}</h2>
                </div>
                {language === "bg" ? (
                  <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.text }}></p>
                ) : (
                  <p>{translate("restaurantsAndBarsP2")}</p>
                )}
              </div>

              <div
                className="col col-auto col-img"
                style={{
                  backgroundImage: `url(${
                    sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.backgroundImage?.[imageIndexes.restaurantApoloniaIndex]
                  })`,
                }}
              >
                &nbsp;
              </div>
            </div>
          </section>

          <section>
            <div
              className="left-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "70%" : "50%",
                left: window.innerWidth < 900 ? "0" : "10px",
              }}
              onClick={() => {
                if (imageIndexes.fioreIndex > 0) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, fioreIndex: prevState.fioreIndex - 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return {
                      ...prevState,
                      fioreIndex: sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.backgroundImage?.length - 1,
                    };
                  });
                }
              }}
            />
            <div
              className="right-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                transform: "rotate(180deg)",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "70%" : "50%",
                right: window.innerWidth < 900 ? "0" : "35%",
              }}
              onClick={() => {
                if (imageIndexes.fioreIndex < sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.backgroundImage?.length - 1) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, fioreIndex: prevState.fioreIndex + 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return { ...prevState, fioreIndex: 0 };
                  });
                }
              }}
            />
            <div className="row">
              <div
                className="col col-auto col-img"
                style={{
                  backgroundPosition: imageIndexes.fioreIndex === 1 ? "10%" : "center",
                  backgroundImage: `url(${
                    sections?.filter((sect) => sect.for === "section_2")?.[0]?.fields?.images?.[imageIndexes.fioreIndex]
                  })`,
                  backgroundSize: "cover",
                }}
              >
                &nbsp;
              </div>
              <div className="col col-auto col-info">
                <div className="headline-box">
                  <h3>{sections?.filter((sect) => sect.for === "section_2")?.[0]?.fields?.title}</h3>
                  {/*<h2>{translate("tAllInclusive")}</h2>*/}
                </div>
                {language === "bg" ? (
                  <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "section_2")?.[0]?.fields?.text }}></p>
                ) : (
                  <p>{translate("restaurantsAndBarsP3")}</p>
                )}
              </div>
            </div>
          </section>

          <section>
            <div
              className="left-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "70%" : "50%",
                left: window.innerWidth < 900 ? "0" : "34%",
              }}
              onClick={() => {
                if (imageIndexes.poolBarIndex > 0) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, poolBarIndex: prevState.poolBarIndex - 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return {
                      ...prevState,
                      poolBarIndex: sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.backgroundImage?.length - 1,
                    };
                  });
                }
              }}
            />
            <div
              className="right-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                transform: "rotate(180deg)",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "70%" : "50%",
                right: window.innerWidth < 900 ? "0" : "10px",
              }}
              onClick={() => {
                if (imageIndexes.poolBarIndex < sections?.filter((sect) => sect.for === "section_3")?.[0]?.fields?.backgroundImage?.length - 1) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, poolBarIndex: prevState.poolBarIndex + 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return { ...prevState, poolBarIndex: 0 };
                  });
                }
              }}
            />
            <div className="row">
              <div className="col col-auto col-info">
                <div className="headline-box">
                  <h2>{translate("tPoolBar")}</h2>
                </div>
                {language === "bg" ? (
                  <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "section_3")?.[0]?.fields?.text }}></p>
                ) : (
                  <p>{translate("restaurantsAndBarsP4")}</p>
                )}
              </div>
              <div
                className="col col-auto col-img"
                style={{
                  backgroundImage: `url(${
                    sections?.filter((sect) => sect.for === "section_3")?.[0]?.fields?.backgroundImage?.[imageIndexes.poolBarIndex]
                  })`,
                }}
              >
                &nbsp;
              </div>
            </div>
          </section>

          <section>
            <div
              className="left-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "65%" : "50%",
                left: window.innerWidth < 900 ? "0" : "10px",
              }}
              onClick={() => {
                if (imageIndexes.apoloniaBarIndex > 0) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, apoloniaBarIndex: prevState.apoloniaBarIndex - 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return {
                      ...prevState,
                      apoloniaBarIndex: apoloniaBarImages.length - 1,
                    };
                  });
                }
              }}
            />
            <div
              className="right-arrow"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(/icons/arrow-left.svg)`,
                transform: "rotate(180deg)",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                cursor: "pointer",
                top: window.innerWidth < 900 ? "65%" : "50%",
                right: window.innerWidth < 900 ? "0" : "35%",
              }}
              onClick={() => {
                if (imageIndexes.apoloniaBarIndex < apoloniaBarImages.length - 1) {
                  setImageIndexes((prevState) => {
                    return { ...prevState, apoloniaBarIndex: prevState.apoloniaBarIndex + 1 };
                  });
                } else {
                  setImageIndexes((prevState) => {
                    return { ...prevState, apoloniaBarIndex: 0 };
                  });
                }
              }}
            />
            <div className="row">
              <div
                className="col col-auto col-img"
                style={{
                  backgroundImage: `url(${apoloniaBarImages[imageIndexes.apoloniaBarIndex]})`,
                  backgroundSize: "cover",
                }}
              >
                &nbsp;
              </div>
              <div className="col col-auto col-info">
                <div className="headline-box">
                  <h3>{translate("apoloniaBarTitle")}</h3>
                </div>
                <p>{translate("apoloniaBarText")}</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer
        fields={sections?.filter((sect) => sect.for === "footer")[0]?.fields}
        phones={sections.filter((sect) => sect.for === "header")[0]?.fields?.phone}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
    sections: state.data.sections,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewRestaurantsAndBars);
