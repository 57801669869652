import React, { Component } from 'react'
import { connect } from 'react-redux'
import {translate} from "../../translations/localization.js"

class TermsAndConditions extends Component {
  render() {
    return (
      <div className="modal"> 
        <div className="modal-content">
          <div className="container">

            <h2 className="space-bottom-md">{translate("tBookingConditions")}</h2>
            <div className="text-2-cols">
                  <p>{translate("termsAndConditionsComingSoon")}</p>
            </div>

          </div>
        </div> 

        <div className="bg-blur"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language
  }
}

export default connect(mapStateToProps)(TermsAndConditions);
