import React, { Component } from "react";
import { connect } from "react-redux";
// import {translate} from "../../translations/localization.js"

class Socials extends Component {
  render() {
    // const {language} = this.props;

    return (
      <div className="socials">
        <a
          target="_blank"
          href="https://www.facebook.com/apoloniaresort/"
          title="Apolonia Resort Hotel - Facebook"
          rel="noopener noreferrer"
        >
          <span className="icon icon-facebook1" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/apoloniaresortsozopol/"
          title="Apolonia Resort Hotel - Instagram"
          rel="noopener noreferrer"
        >
          <span className="icon icon-instagram1" />
        </a>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.language
  };
};

export default connect(mapStateToProps)(Socials);
