import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "../../translations/localization.js";
class HeaderTop extends Component {
  render() {
    // const {language} = this.props;
    console.log(this.props.fields && this.props.fields.fields.phone);
    return (
      <div className="header-top">
        <div className="col-left">
          <span>
            <span className="icon icon-location" /> {this.props.fields && this.props.fields.fields.address} | {translate("tReception")}:{" "}
            {this.props.fields && this.props.fields.fields.phone.split(";")[0].split(":")[1]}
          </span>
          {/* <span>{translate("tHotelApoloniaEmail")}</span> */}
          {/* <span>{translate("tPhoneNumber1")}</span>
          <span>{translate("tPhoneNumber2")}</span> */}
        </div>
        <div className="col-right">
          {/* <Socials /> */}
          <span>
            {translate("forReservations")}:{" "}
            {this.props.fields &&
              this.props.fields.fields.phone
                .split(";")
                .slice(1)
                .map((phone, i) => i > 0 ? phone.split(":")[1] : phone.split(":")[1] + ", ")}{" "}
            (08:00 - 18:00) | {this.props.fields && this.props.fields.fields.email}
          </span>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(HeaderTop);
