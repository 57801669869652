import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Footer, ReservationBanner } from "../../../components";
import { translate } from "../../../translations/localization.js";
import { getData } from "../../../actions";
import RoomTypesList from "../RoomTypesList.js";
import GallerySlider from "./GallerySlider.js";
import { useEffect } from "react";

const NewDoubleRoom = ({ sections, getData, language }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getData({ page: "double-room" });
  }, []);
  return (
    <div className="wrap-all room-types double-room">
      <Header fields={sections.filter((sect) => sect.for === "header")[0]} />

      <section
        className="section-header overlay-gradient-4"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "landing")?.[0]?.fields?.backgroundImage?.[0]})`,
        }}
      >
        <div className="flex align-middle">
          <div className="container">
            <h1>{translate("doubleRoomMainTitle")}</h1>
          </div>
        </div>
        <ReservationBanner />
      </section>

      <div className="main">
        <div className="container">
          <div className="bg-full-left-headline">
            <h2 className="page-headline headline-up">{translate("tDoubleRoom")}</h2>
            <div className="bg-full-left" />
          </div>

          <div className="row row-1">
            <div className="col col-auto col-50 bg-2">
              <div className="col-bg-spaces">
                <div className="bg-headline">
                  <h3 className="color-white">{translate("tAmenities")}</h3>
                </div>

                <div className="amenities">
                  <span className="icon-tv" />
                  <span className="icon-minibar" />
                  <span className="icon-pool" />
                  <span className="icon-cocktail" />
                  <span className="icon-party" />
                </div>

                <div className="amenities-list">
                  <ul>
                    {language === "bg" ? (
                      sections &&
                      sections
                        ?.filter((sect) => sect.for === "features")?.[0]
                        ?.fields?.features?.slice(0, 3)
                        ?.map((feat) => {
                          return <li>{feat}</li>;
                        })
                    ) : (
                      <>
                        <li>{translate("tParkingLot")}</li>
                        <li>{translate("tFitness")}</li>
                        <li>{translate("tWiFi")}</li>
                      </>
                    )}
                  </ul>
                  <ul>
                    {language === "bg" ? (
                      sections &&
                      sections
                        ?.filter((sect) => sect.for === "features")?.[0]
                        ?.fields?.features?.slice(3, sections?.filter((sect) => sect.for === "features")?.[0]?.fields?.features?.length)
                        ?.map((feat) => {
                          return <li>{feat}</li>;
                        })
                    ) : (
                      <>
                        <li>{translate("tBreakfast")}</li>
                        <li>{translate("tKidsSport")}</li>
                        <li>{translate("tUmbrellaSunLounger")}</li>
                      </>
                    )}
                  </ul>
                </div>
                {language === "bg" ? (
                  <p
                    className="font-size-sm"
                    dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "features")?.[0]?.fields?.text }}
                  ></p>
                ) : (
                  <p>{translate("tVATP")}</p>
                )}
              </div>
            </div>
            <div className="col col-auto col-45">
              <div className="col-spaces">
                {language === "bg" ? (
                  <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "text")?.[0]?.fields?.text }}></p>
                ) : (
                  <>
                    {translate("doubleRoomP1")}
                    {translate("doubleRoomP2")}
                    <br />
                    <br />
                    {translate("doubleRoomP3")}
                    {translate("doubleRoomP4")}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row cols-to-right">
            <div className="col col-auto col-45 bg-2 relative">
              <div className="col-spaces">
                <h3 className="align-center color-white">{translate("tAdditionalАmenities")}</h3>
              </div>
              <div className="bg-full-right" />
            </div>
          </div>

          <div className="row bg-2 col-bg-spaces">
            <div className="col">
              <h4 style={{ display: "flex" }}>
                <span className="icon icon-baby" />
                {language === "bg" ? (
                  <p
                    dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "additional-features")?.[0]?.fields?.title2 }}
                  ></p>
                ) : (
                  <p>{translate("tBabyCot")}</p>
                )}
              </h4>
              {language === "bg" ? (
                <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "additional-features")?.[0]?.fields?.text2 }}></p>
              ) : (
                <p>{translate("tBabyCotP")}</p>
              )}
            </div>
            <div className="col">
              <h4 style={{ display: "flex" }}>
                <span className="icon icon-pet" />
                {language === "bg" ? (
                  <p
                    dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "additional-features")?.[0]?.fields?.title3 }}
                  ></p>
                ) : (
                  <p>{translate("tPets")}</p>
                )}
              </h4>
              {language === "bg" ? (
                <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "additional-features")?.[0]?.fields?.text3 }}></p>
              ) : (
                <p>{translate("tPetsP")}</p>
              )}
            </div>
          </div>

          <GallerySlider images={sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.images} />

          <div className="m-align-center">
            <h3 className="headline-up">{translate("tOtherRooms")}</h3>
          </div>

          <RoomTypesList />
        </div>
      </div>

      <Footer fields={sections?.filter((sect) => sect.for === "footer")[0]?.fields}  phones={sections.filter((sect) => sect.for === "header")[0]?.fields?.phone}/>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
    sections: state.data.sections,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewDoubleRoom);
