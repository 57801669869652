import { LanguageTypes } from '../actions'

const initialState = "bg"

const language = (state = initialState, action) => {
  switch (action.type) {
   case LanguageTypes.SET_LANGUAGE:
     return action.payload;
     default:
       return state;
  }
}

export default language
