import React, { Component } from "react";
import "./styles.css";
import { setLanguage } from "../../actions";
import { connect } from "react-redux";
import { setLocale } from "../../translations/localization";
import { withRouter } from "react-router-dom";

class NavLangs extends Component {
  changeLang = (lang) => {
    setLocale(lang);
    this.props.setLanguage(lang);
    this.props.history.push(
      `/${lang}/${window.location.pathname.split("/")[2] || ""}`
    );
  };

  render() {
    console.log();
    return (
      <div>
        <ul className="nav-langs">
          <li
            className={`${
              window.location.pathname.split("/")[1] === "bg"
                ? "item-selected"
                : "item"
            }`}
            style={{ fontSize: "16px" }}
          >
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                this.changeLang("bg");
              }}
            >
              BG
            </a>
          </li>
          <li className="divider">|</li>
          <li
            className={`${
              window.location.pathname.split("/")[1] === "en"
                ? "item-selected"
                : "item"
            }`}
            style={{ fontSize: "16px" }}
          >
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                this.changeLang("en");
              }}
            >
              EN
            </a>
          </li>
          {/*   <li className="item"><a href="javascript:void(0)" onClick={() => this.changeLang("de")}>DE</a></li>
          <li className="item"><a href="javascript:void(0)" onClick={() => this.changeLang("ru")}>RU</a></li>  */}
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(setLanguage(lang)),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(NavLangs)
);
