import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { NavMain } from "../../components";
import { HeaderTop } from "./index";
import "./styles.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  state = {
    isPageTop: true,
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isPageTop = window.scrollY < 100;
      if (isPageTop !== this.state.isPageTop) {
        this.setState({ isPageTop });
      }
    });
  }

  render() {
    const { language } = this.props;

    const toggleNavIsOpen = this.state.isOpen;
    let toggleNavStyles;

    if (toggleNavIsOpen) {
      toggleNavStyles = "nav-main-toggle isOpen";
    } else {
      toggleNavStyles = "nav-main-toggle";
    }

    const bodyStyle = "<style>body {overflow:hidden !iportant}</style>";

    return (
      <div className={this.state.isPageTop ? "header" : "header scrolled"}>
        {this.state.toggleNavIsOpen ? bodyStyle : null}

        <div className="header-container">
          <HeaderTop fields={this.props.fields}/>

          <div className="header-content">
            <div className="col-logo">
              <Link to={`/${language}`} className="logo">
                <img alt="Apolonia Resort Hotel" src="/apolonia-resort-hotel.png" />
              </Link>
            </div>

            <div className={toggleNavStyles}>
              <div className="burger-icon">
                <div id="burger-icon" onClick={this.toggleMenu.bind(this)}>
                  <span />
                  <span />
                  <span />
                </div>
              </div>

              <div className="col-navmain">
                <div className="col-logo-m">
                  <Link to={`/${language}`} className="logo">
                    <img alt="Apolonia Resort Hotel" src="/apolonia-resort-hotel-m.png" />
                  </Link>
                </div>

                <div>
                  <NavMain  />
                </div>

                <div className="m-header-top">
                  <HeaderTop />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(Header);
