import { combineReducers } from "redux";
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from "react-router-redux";

import language from "./language";
import starter from "./starter";
import data from "./data";

export default combineReducers({
  starter,
  routerReducer,
  data,
  language,
});
