import React, { Component } from "react";
import { Base64 } from "js-base64";
import { connect } from "react-redux";
import { translate } from "../../translations/localization.js";
import "./styles.css";

class FormContactUs extends Component {
  constructor() {
    super();
    this.state = {
      today: new Date(),
      namefrom: "",
      emailfrom: "",
      phone: "",
      message: "",
      to:
        "reservation@apoloniaresort.bg,reservation@apoloniaresort.com,plamen.hinchovski@gmail.com,manager@thelodgehotel.eu,thesocourt@gmail.com",
      subject: "Apolonia Resort Hotel - Contact us",
      loading: false
    };
  }

  onChange = e => {
    /* update the state  */
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit(e) {
    this.setState({ loading: true });

    e.preventDefault();
    window
      .fetch("https://mailer.fidweb.net/mail/send/apolonia-resort", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${Base64.encode(
            `apoloniaResort:apoDr1v33423+,!231!!asd1Fdss`
          )}`
        },
        body: JSON.stringify({
          from: this.state.emailfrom,
          to: this.state.to,
          subject: this.state.subject,
          body: ` Изпратено от: ${this.state.namefrom},\n Email: ${
            this.state.emailfrom
          },\n Телефон: ${this.state.phone},\n\n Съобщение: ${
            this.state.message
          }`,
          notifyBody: `

        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>APOLONIA RESORT HOTEL</title>
          <link href="https://fonts.googleapis.com/css?family=Cormorant+Infant:300,400,500,600,700&amp;subset=cyrillic,cyrillic-ext,latin-ext" rel="stylesheet">
          <style type="text/css">
            * {font-family:'Cormorant Infant','Times New Roman',Times,serif; }
            #outlook a {padding: 0;}
            body {width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin: 0; padding: 0; font-weight:400; color:#6D6158;}
            img {outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;}
            a img {border: none; }
            h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
            h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
            h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {font-family:'Cormorant Infant','Times New Roman',Times,serif; color: #C8A264;}
            a {outline: none; text-decoration: none; color: #C8A264; }
            @media only screen and (max-width:600px) {
              *[class].full {width: 100% !important; overflow: hidden !important;}
              *[class].cen {margin: 0 auto !important; text-align: center !important;}
              *[class].img-full {display: block !important; width: 100% !important; height: auto !important;}
            }
          </style>
        </head>
        <body style="margin:0;padding:0;background-color:#ffffff;font-family:'Cormorant Infant','Times New Roman',Times,serif;font-size:18px;">
          <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
            <tr>
              <td valign="top" bgcolor="#ffffff" align="center" width="100%">
                <table cellpadding="0" cellspacing="0" border="0" align="center" valign="top" width="600" class="full">
                  <tr>
                    <td valign="top">

                        <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" height="20" width="1" border="0" style="display:block;" /></td></tr></table>

                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr>
                            <td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" width="10" height="1" /></td>
                            <td style="text-align:left;font-size:14px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">${translate(
                              "formContactUsSentInquiry"
                            )}</td>
                            <td style="text-align:right;font-size:14px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">дата ${
                              this.state.today
                            }г.</td>
                            <td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" width="10" height="1" /></td>
                          </tr>
                          <tr><td colspan="4"><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" width="10" height="10" /></td></tr>
                          <tr><td align="center" colspan="4" style="text-align: center;"><a href="https://apoloniaresort.com/" target="_blank" style="text-decoration:none;font-size:32px;color:#6D6158;font-weight:bold;font-family: 'Cormorant Infant','Times New Roman',Times,serif; "><img src="https://apoloniaresort.com/email/apolonia-resort-hotel-logo.jpg" alt="APOLONIA RESORT HOTEL" border="0" /></a></td></tr>
                        </table>

                        <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>

                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr><td align="center"><a href="https://apoloniaresort.com/" target="_blank" style="text-decoration:none;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;"><img src="https://apoloniaresort.com/email/apolonia-email-header.jpg"alt="APOLONIA RESORT HOTEL" border="0" class="img-full" /></a></td></tr>
                        </table>


                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                            <tr>
                            <td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" width="10" height="1" /></td>
                            <td style="text-align: left">

                                <h1 style="font-size:34px;line-height:24px;color:#6D6158;font-family: 'Cormorant Infant','Times New Roman',Times,serif; ">${translate(
                                  "tHotelApolonia"
                                )}</h1>

                                <p style="font-size:18px;line-height:28px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">${translate(
                                  "formContactUsP1"
                                )}</p>

                                <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                <tr>
                                    <td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" width="20" height="1" /></td>
                                    <td align="left">
                                        <p style="font-size:18px;line-height:28px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">
                                            ${translate("tSentBy")}: ${
            this.state.namefrom
          }<br/>
                                            ${translate("tEmail")}: ${
            this.state.emailfrom
          }<br/>
                                            ${translate("tTelephone")}: ${
            this.state.phone
          }<br/>
                                            ${translate("tMessage")}: ${
            this.state.message
          }
                                        </p>
                                    </td>
                                    </tr>
                                </table>

                                <p style="font-size:18px;line-height:28px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">
                                ${translate("formContactUsP2")}<br/>
                                ${translate("formContactUsP3")}
                                </p>

                            </td>
                            <td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" width="10" height="1" /></td>
                            </tr>
                        </table>


                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                        <tr>
                            <td style="text-align:center;">

                                <p style="text-align:center;font-size:14px;color:#6D6158;font-family: 'Cormorant Infant','Times New Roman',Times,serif; "><a href="https://apoloniaresort.com/" target="_blank" style="text-decoration:none;font-size:14px;color:#6D6158;font-weight:bold;font-family: 'Cormorant Infant','Times New Roman',Times,serif; "><img src="https://apoloniaresort.com/email/apolonia-resort-hotel-logo-footer.jpg"alt="APOLONIA RESORT HOTEL" border="0" /></a></p>

                            <p style="text-align:center;font-size:14px;line-height:21px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">
                                <span style="text-transform:lowercase">${translate(
                                  "tTelephone"
                                )}</span>:  ${translate(
            "tPhoneNumber1"
          )}, ${translate("tPhoneNumber2")}<br/>
                                <span style="text-transform:lowercase">${translate(
                                  "tEmail"
                                )}</span>: ${translate(
            "tHotelApoloniaEmail"
          )}<br/>
                                <span style="text-transform:lowercase">${translate(
                                  "tAddress"
                                )}</span>: ${translate("tHotelApoloniaAddress")}
                            </p>

                            <p style="text-align:center;font-size:14px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">
                                <a target="_blank" href="https://www.facebook.com/apoloniaresort/" style="text-decoration: none"><img src="https://apoloniaresort.com/email/apolonia-email-facebook.jpg" alt="APOLONIA RESORT HOTEL - Facebook" /></a>
                                &nbsp;
                                <a target="_blank" href="https://www.instagram.com/apoloniaresortsozopol/" style="text-decoration: none"><img src="https://apoloniaresort.com/email/apolonia-email-instagram.jpg" alt="APOLONIA RESORT HOTEL - Instagram" /></a>
                            </p>

                            <p style="text-align:center;font-size:14px;color:#6D6158;font-family:'Cormorant Infant','Times New Roman',Times,serif;">${translate(
                              "footerCopyright"
                            )}</p>

                            </td>
                        </tr>
                        </table>

                        <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://apoloniaresort.com/email/apolonia-email-pix.gif" height="40" width="1" border="0" style="display:block;" /></td></tr></table>

                </td>
                </tr>
            </table>
            </td>
        </tr>
        </table>
        </body>
        </html>

        `,
          notifySubject: `${translate("tHotelApolonia")} - ${translate(
            "formContactUsInquiryReceived"
          )}`
        })
      })
      .then(result => {
        alert(`${translate("formContactUsP4")}`);
        // console.log("RESULTS:", result)
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { namefrom, emailfrom, phone, message, loading } = this.state;

    return (
      <div className="form-contactus">
        <form id="contact-us" onSubmit={this.onSubmit.bind(this)}>
          <div className="cell">
            <input
              type="text"
              name="namefrom"
              id="namefrom"
              placeholder={translate("tName")}
              value={namefrom}
              onChange={this.onChange}
            />
          </div>
          <div className="cell">
            <input
              type="text"
              name="emailfrom"
              id="emailfrom"
              placeholder={translate("tEmail")}
              value={emailfrom}
              onChange={this.onChange}
            />
          </div>
          <div className="cell">
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder={translate("tTelephone")}
              value={phone}
              onChange={this.onChange}
            />
          </div>
          <div className="cell">
            <textarea
              name="message"
              id="message"
              placeholder={translate("tInquiry")}
              value={message}
              onChange={this.onChange}
            />
          </div>
          <div className="cell">
            <button type="submit" className="bttn-submit" disabled={loading}>
              {/* {loading && (
                      <i
                        // className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )} */}
              {!loading && <span>{translate("tSend")}</span>}
              {loading && <span>{translate("tSendInProgress")}</span>}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.language
  };
};

export default connect(mapStateToProps)(FormContactUs);
