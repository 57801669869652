import { ofType, ActionsObservable } from "redux-observable";
import { DataTypes, getDataSuccess, updateDataSuccess } from "../actions";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { API_URL } from "../config/settings";

export const getDataEpic = (action$, state$) => {
  return action$.pipe(
    ofType(DataTypes.GET_DATA),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}sections/${payload.page}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            console.log(response);
            obs.next(getDataSuccess(response.payload));
            if (payload.onSuccess) payload.onSuccess(response.payload);
          });
        }),
        catchError((err) => {
          console.log("ERR", err);
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.next({ type: "main/ERRORS", payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};
