import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Footer, ReservationBanner } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.css";

class Events extends Component {
  render() {
    return (
      <div className="wrap-all sport-and-spa">
        <Header />

        <section
          className="section-header overlay-gradient-5"
          style={{
            backgroundImage: 'url("/imgs/apolonia-hotel-sport-header.jpg")',
          }}
        >
          <div className="flex align-bottom">
            <div className="container">
              <h1>{translate("activities")}</h1>
              <p>{translate("activitiesText")}</p>
            </div>
          </div>
          <ReservationBanner />
        </section>
        <div className="section-container">
          <div className="event-image-2" />
          <div className="text-container">
            <div className="text-banner">{translate("eventTitle2")}</div>
            <p>{translate("homeEventText")}</p>
          </div>
        </div>
        <div className="section-container">
          <div className="text-container">
            <div className="text-banner">July Morning</div>
            <p>{translate("eventText1")}</p>
          </div>
          <div className="event-image" />
        </div>
        <section className="section-sport-and-spa">
          <div className="container">
            <div className="row">
              <div className="col col-auto">
                <div className="sport-box">
                  <div className="img img-1">
                    <img src="/eventImages/event-1.jpg" alt="" />
                  </div>
                  <h3>{translate("conferences")}</h3>
                </div>
              </div>
              <div className="col col-auto">
                <div className="sport-box">
                  <div className="img">
                    <img src="/eventImages/event-2.jpg" alt="" />
                  </div>
                  <h3>{translate("parties")}</h3>
                </div>
              </div>
              {/*Тук детският кът е на мястото на тенис корта */}
              <div className="col col-auto">
                <div className="sport-box">
                  <div className="img">
                    <img src="/eventImages/event-3.jpg" alt="" />
                  </div>
                  <h3>{translate("weddings")}</h3>
                </div>
              </div>
              <div className="col col-auto">
                <div className="sport-box">
                  <div className="img">
                    <img src="/eventImages/event-4.jpg" alt="" />
                  </div>
                  <h3>{translate("teambuilding")}</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(Events);
